window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

/**
 * Custom
 */

 document.addEventListener("DOMContentLoaded", function() {

     // Call Api when form is submitted
     $('#form-contact').on('submit', function (event) {

         event.preventDefault()

         axios.post($(this).attr('action'), {
             source: $(this).find('input[name="source"]').val(),
             firstname: $(this).find('input[name="firstname"]').val(),
             lastname: $(this).find('input[name="lastname"]').val(),
             postcode: $(this).find('input[name="postcode"]').val(),
             email: $(this).find('input[name="email"]').val()
         })
         .then(function (response) {

             $('#form-contact').find('.alert')
                .removeClass('alert-danger')
                .addClass('alert-success')
                .removeClass('d-none')
                .text('Wir haben Ihre Anfrage erhalten! Ein Mitarbeiter wird sich in kürze mit Ihnen in verbindung setzen.')

         })
         .catch(function (error) {

             $('#form-contact').find('.alert')
                .removeClass('alert-success')
                .addClass('alert-danger')
                .removeClass('d-none')
                .text('Es gab einen Fehler beim verarbeiten Ihrer Anfrage. Bitte versuchen Sie es später erneut.')

         });

     })

 });
